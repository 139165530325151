<template>
	<div class="wrap">
	

		<div class="">
			<div class="goods-title">
				<h4>{{ $lang(goods,'title') }}</h4>
			</div>
			<div class="goods-params">
				<div>
					<div class="goods-imgs">
						<img :src=" $req.getFileUrl(goods.img)" border="0"
							width="300" v-if="goods.img" alt=""/>
					</div>
					<div style="display: flex;align-items: center;width: 300px;position: relative;margin-top: 10px;">
				
						<el-image v-for="(item,index) in goods.banner" :key="index" v-if="goods.banner.length"
							style="width: 50px; height: 50px;margin-right: 5px;"
							:src="$req.getFileUrl(item)" @click="checkImg(item,fileServer)">
						</el-image>

				
					</div>
				</div>

				<div class="goods-content">
					<el-form label-width="200px">
						<el-form-item :label="$t('mall.typeNumber')">
							<span>{{ goods.model}}</span>
						</el-form-item>
						<el-form-item :label="$t('goodsDetail.goodsPrice')">
							<span style="color: #E70000;">￥{{ currentobj.price | toFix}}</span>
						</el-form-item>
						<el-form-item :label="$t('goodsDetail.smaplePrice')">
							<span >￥{{ currentobj.priceSample | toFix}}</span>
						</el-form-item>
						<el-form-item :label="$t('goodsDetail.sale')">
							<span>{{ goods.sales }}{{ $t("mall.piece") }}</span>
						</el-form-item>
						<el-form-item :label="$t('mall.MinimumOrderQuantity')">
							<span>{{goods.minimumQuantity?goods.minimumQuantity:1}}{{ $t("mall.piece") }}</span>
						</el-form-item>
						<!-- <el-form-item :label="$t('goodsDetail.standardNember')">
							<span>{{goods.model}}</span>
						</el-form-item> -->
						<el-form-item :label="$t('goodsDetail.TransactionTime')" v-if="goods.TransactionTime">
							<span>{{goods.TransactionTime}}{{ $t("unit.day") }}</span>
						</el-form-item>
						
						<el-form-item :label="$t('newadd.t.规格')" >
							<el-row v-for="(item,index) in goods.specList" :key="index" type="flex" align="center" class="e-margin-bottom-10">
								<el-row style="color: #cccccc;">{{$lang(item,'name')}}</el-row>
								<div v-for="(item1,index1) in item.itemList" :key="index1" class="block"
									:class="[item1.select?'change':'']" @click="select(item1,item)">
									{{$lang(item1,'name')}}
								</div>
							</el-row>
						</el-form-item>
						<!-- 数量 -->
						<el-form-item :label="$t('goodsDetail.num')" >
							<el-input-number v-model="gooodnum"  :min="min" step-strictly :step="addNum">
							</el-input-number>
						</el-form-item>
						
						
						<!-- 付款类型 -->
						<el-form-item :label="$t('goodsDetail.supplyModes')">
							<el-select v-model="bookStatus" size="small" >

								<el-option value="bookSign" :label="$t('goodsDetail.smaplePriceValue[1]')  "></el-option>
								<el-option value="bookAll" :label="$t('goodsDetail.smaplePriceValue[0]')  "></el-option>
							</el-select>
						</el-form-item>
						
						<!-- 包装大小 -->
						<el-form-item :label="$t('goodsDetail.packNum')">
							<el-select v-model="size" size="small">
						
						
								<el-option value="sizeSmall"  :label="$t('goodsDetail.boxSizeValue[0]')  "></el-option>
								<el-option value="sizeBig" :label="$t('goodsDetail.boxSizeValue[1]')  "></el-option>
							</el-select>
						</el-form-item>
						
						
						
					<el-form-item>
						
						<el-button type="primary" @click="orderNow">{{ $t("operation.orderNow") }}</el-button>
						
					</el-form-item>
					</el-form>
					
					
				</div>
			</div>
	
			<div class="goods-info">
				<div class="goods-info-title">
					<h4>{{ $t("goodsDetail.goodsInfo") }}</h4>
				</div>
				<el-row>
					<el-col v-for="item in goods.attrList" :span="12" style="text-align: center;padding: 20px;" :key="item.id">
						{{$lang(item,'name')}}: {{$lang(item,'value')}}

					</el-col>
				</el-row>
			</div>

			<hr>

			<div class="goods-detail" style="padding-top: 20px;">


				<p v-html="$lang(goods,'content')"></p>

			</div>
		</div>
	</div>
</template>

<script>
	import {
		getGoodsDetail
	} from "@/api/goods";

	import {
		mapState,
		mapMutations
	} from "vuex";

	export default {
		name: 'goodsDetail',
		computed: {
			...mapState(["fileServer"]),
			watchStatus() {
				const bookStatus = this.bookStatus;
				const currentobj = this.currentobj;
				const size = this.size;
				return {
					bookStatus,
					currentobj,
					size
				}
			},
		},
		watch:{
			// 监听
			watchStatus: {
				immediate: true,
				handler(v){
					var {bookStatus,currentobj,size}=v;			
					if(currentobj.code){
						this.currentobj=currentobj;
						console.log(currentobj,"currentobj")
						//样品订购
						if(v.bookStatus=='bookSign'){
							this.min=currentobj.minimumQuantity;
							this.addNum=1;
							this.min=1;
							this.gooodnum=1;
						
						}
						// 批量订购
						else{
							console.log(currentobj,"c")
			                 // 批量订购小包装
							if(size=='sizeSmall'){
								this.min=currentobj.productNumSmall;
								this.addNum=currentobj.productNumSmall;
								this.gooodnum=currentobj.productNumSmall;
							}else
							// 批量订购大包装
							{
								this.min=currentobj.productNumLarge;
								this.addNum=currentobj.productNumLarge;
								this.gooodnum=currentobj.productNumLarge;
							}
							
						}
					}
					
				}
			},
		},

		data() {

			return {
				size: 'sizeSmall',
				bookStatus: 'bookSign',
				gooodnum: "",
				goods: {

				},
				currentobj: {},
				currentInfo:{},
                id:'',
				min: 1,
				max: '',
				addNum:1
			}
		},
		created() {
			this.id = this.$route.query.id;
		    this.detail(this.id)
		},
		
		methods: {
			
			detail(id){
				getGoodsDetail(id).then(res => {
					res.banner = res.banner.split(",");
					if(res.specList.length){
						res.specList.forEach(item=>{
							item.itemList.forEach(item1=>{
								item1.select = false
							})
						})
					}
					this.goods = res;
					
				}).catch(err => {
				})
			},
			orderNow() {

				if (Object.keys(this.currentobj).length) {		
					if (!this.currentobj.num) {
						this.$message({
							message: '当前库存为空',
							type: 'warning'
						});
						return
					}
				}else{
					this.$message({
						message: '您还没有选择商品规则',
						type: 'warning'
					});
					return
				}
				
				var price = '';
				switch (this.bookStatus) {
					case 'bookSign':
						price = this.currentobj.uniMoneySample
						break;
					case 'bookAll':
						price = this.currentobj.uniMoneyBatch
						break;
				}
				
				var obj = this.currentobj;
				obj.gooodnum = this.gooodnum;
				obj.size = this.size == 'sizeBig' ? 'L' : this.size == 'sizeSmall' ? 'S' : '';
				obj.price = this.bookStatus == 'bookAll' ? this.currentobj.uniMoneyBatch : this.bookStatus == 'bookSign' ? this
					.currentobj.uniMoneySample : '';

				this.$router.push({
					name: "goodsPay",
				})
				
				this.currentobj.pic=this.goods.img;
				
				this.$store.commit("SET_PARAMS", [this.currentobj])
				
				
				
			},
			checkImg(item, fileServer) {
				this.goods.img =  this.$req.getFileUrl(item) 
				
			},
			select(item1, item) {
				item.itemList.forEach(item => {
					item.select = false
				})
				this.$set(item1, "select", true);
				let arrselect = [];
				this.goods.specList.filter(item => {
					item.itemList.filter(item => {
						if (item.select) {
							arrselect.push(item.id)
						}

					})
				})
				var obj = this.goods.sukList.filter(item => item.code === arrselect.join(","))
				if (obj.length) {
					if (!obj[0].num) {
						this.$message({
							message: '库存为空',
							type: 'warning'
						});
					}
					obj[0].gooodnum=this.gooodnum;
					obj[0].titleEnUs=this.goods.titleEnUs;
					obj[0].titleZhCn=this.goods.titleZhCn;
					obj[0].currentPrice=this.bookStatus=='bookSign'?this.goods.priceMax:this.goods.priceMin;
			     	this.currentobj = obj[0];
					
					
				}
			}
		}
	}
</script>

<style scoped>
	@import url("./DetailCommon.css");
</style>
